import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Layout } from "~/components";
import { chardhamLinks } from "~/constants/links";

const BlogListing: React.FC = () => {
  return (
    <Layout links={chardhamLinks}>
      <Heading className="text-center" type="h2">
        Blogs
      </Heading>
      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Link to="/blogs/history-of-chardham-unleash-chardham-by-helicopter">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/blog-1-cover-image.png"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  History of Chardham:
                  <br /> Unleash Chardham Yatra by Helicopter
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/chardham-best-tourist-destinations">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/chardham-new.jpeg"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham by Helicopter: <br />
                  Best Tourist Destinations
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/travel-toolkit-chardham">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/headgear.png"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham Yatra by Helicopter:
                  <br /> A Comprehensive Traveler's Toolkit
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/benefits-of-helicopter-travel">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/bg/1-chardham-carousel.png"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham by Helicopter:
                  <br /> Benefits of Helicopter Travel
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/guide-to-planning-your-yatra">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/blog-guide.png"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham by Helicopter:
                  <br /> Guide to Planning your Yatra
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/chardham-best-service-provider">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/blog-cover-6.jpg"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham by Helicopter
                  <br />
                  Best Service Provider
                </Heading>
              </div>
            </div>
          </Link>

          <Link to="/blogs/chardham-yatris-experience">
            <div className="bg-white rounded overflow-hidden shadow-lg h-[100%]">
              <StaticImage
                src="../../images/bg/2-carousal-1.png"
                alt="icon-testimonial"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <div
                className="px-4 pb-2"
                style={{
                  borderTop: "1px solid #ccc",
                }}
              >
                <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                  Chardham Yatra by Helicopter
                  <br />
                  Yatris' Experience
                </Heading>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default BlogListing;
